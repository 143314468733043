// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Card from "../../../../../ui/src/card/Ui__Card.bs.js";
import * as App__Report from "../../../utils/App__Report.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__AppLink from "../../../../../ui/src/routing/Ui__AppLink.bs.js";
import * as Ui__Styling from "../../../../../ui/src/styling/Ui__Styling.bs.js";
import * as ReactRelay from "react-relay";
import * as ReactNative from "react-native";
import * as Ui__ProductTag from "../../../../../ui/src/productTag/components/Ui__ProductTag.bs.js";
import * as Ui__Responsive from "../../../../../ui/src/responsive/Ui__Responsive.bs.js";
import * as Ui__ImageFallback from "../../../../../ui/src/image/components/imageFallback/Ui__ImageFallback.bs.js";
import * as App__Product__Card from "../card/App__Product__Card.bs.js";
import * as Bool$DecidrIoUtils from "@decidr-io/utils/src/bool/Bool.bs.js";
import * as App__Product__Price from "../price/App__Product__Price.bs.js";
import * as App__Product__SaleTag from "../saleTag/App__Product__SaleTag.bs.js";
import * as Ui__BrandNameFallback from "../../../../../ui/src/brandNameFallback/Ui__BrandNameFallback.bs.js";
import * as Ui__ProductCard__Base from "../../../../../ui/src/productCard/Ui__ProductCard__Base.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as SimpleReact$DecidrIoUtils from "@decidr-io/utils/src/react/SimpleReact.bs.js";
import * as Ui__ProductPrelovedOnlyTag from "../../../../../ui/src/productTag/components/Ui__ProductPrelovedOnlyTag.bs.js";
import * as App__Product__CommonStyling from "../commons/App__Product__CommonStyling.bs.js";
import * as FeatureFlags__LocalFeatureFlags from "../../../../../feature-flags/src/local/FeatureFlags__LocalFeatureFlags.bs.js";
import * as AppProductScrollableListCard_product_graphql from "../../../../../relay-codegen/generated/AppProductScrollableListCard_product_graphql.bs.js";

function use(fRef) {
  var data = ReactRelay.useFragment(AppProductScrollableListCard_product_graphql.node, fRef);
  return RescriptRelay_Internal.internal_useConvertedValue(AppProductScrollableListCard_product_graphql.Internal.convertFragment, data);
}

function App__Product__ScrollableListCard$GrowingSpacerWithLimitedHeight(Props) {
  var givenMinSize = Props.minSize;
  var givenMaxSize = Props.maxSize;
  var orientationOpt = Props.orientation;
  var orientation = orientationOpt !== undefined ? orientationOpt : "vertical";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var minSize = Belt_Option.getWithDefault(givenMinSize, theme.spacing._3);
  var maxSize = Belt_Option.getWithDefault(givenMaxSize, theme.spacing._6);
  return React.createElement(ReactNative.View, {
              style: orientation === "horizontal" ? ({
                    flex: 1,
                    maxWidth: maxSize,
                    minWidth: minSize
                  }) : ({
                    flex: 1,
                    maxHeight: maxSize,
                    minHeight: minSize
                  })
            });
}

var GrowingSpacerWithLimitedHeight = {
  make: App__Product__ScrollableListCard$GrowingSpacerWithLimitedHeight
};

var match = Ui__Styling.describe({
      container: {
        TAG: /* Dual */2,
        _0: {
          flex: 1,
          justifyContent: "space-between"
        },
        _1: (function (props) {
            return {
                    backgroundColor: props.theme.colors.surface,
                    maxWidth: props.cardMaxWidth,
                    padding: props.cardPadding
                  };
          })
      },
      image: {
        TAG: /* Dynamic */1,
        _0: (function (props) {
            return {
                    backgroundColor: props.theme.colors.surface
                  };
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

var imageAspectRatio = 7 / 5;

function App__Product__ScrollableListCard(Props) {
  var product = Props.product;
  var imagePriority = Props.imagePriority;
  var imageSizes = Props.imageSizes;
  var testIDOpt = Props.testID;
  var testID = testIDOpt !== undefined ? testIDOpt : "product-scrollable-list-card";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var media = Ui__Responsive.useMedia(undefined)(undefined);
  var match$1 = App__Product__CommonStyling.useProductCommonStyling(undefined);
  var match$2 = Curry.app(media.mediaSelect, [
        undefined,
        {
          imageHeight: 86,
          cardSpacing: theme.spacing._16,
          cardTextSectionSpacing: theme.spacing._2,
          isWideScreen: false,
          cardMaxWidth: 160
        },
        undefined,
        {
          imageHeight: 110,
          cardSpacing: theme.spacing._20,
          cardTextSectionSpacing: theme.spacing._3,
          isWideScreen: false,
          cardMaxWidth: 198
        },
        undefined,
        {
          imageHeight: 138,
          cardSpacing: theme.spacing._24,
          cardTextSectionSpacing: theme.spacing._4,
          isWideScreen: true,
          cardMaxWidth: 248
        },
        undefined,
        {
          imageHeight: 72,
          cardSpacing: theme.spacing._12,
          cardTextSectionSpacing: theme.spacing._2,
          isWideScreen: false,
          cardMaxWidth: 136
        },
        undefined
      ]);
  var isWideScreen = match$2.isWideScreen;
  var imageHeight = match$2.imageHeight;
  var styled = Curry._1(stylesheet, {
        theme: theme,
        cardPadding: match$1.cardPadding,
        cardMaxWidth: match$2.cardMaxWidth
      });
  var match$3 = use(product);
  var fragmentRefs = match$3.fragmentRefs;
  var name = match$3.name;
  var mainImage = match$3.mainImage;
  var brand = match$3.brand;
  var match$4 = Curry._1(Ui__AppLink.useRouting, undefined);
  var routeConfiguration = Curry._3(Ui__AppLink.makeConfiguration, {
        TAG: /* Product */3,
        slug: match$3.slug,
        productId: match$3.id,
        variantId: match$3.defaultVariant.id
      }, undefined, undefined);
  var a11yLabel = "See more information about " + name + "";
  var textStyle = Bool$DecidrIoUtils.thenSome(!isWideScreen, {
        maxWidth: imageHeight * imageAspectRatio
      });
  var onPress = Belt_Option.map(match$4.nativeNavigationHandler, (function (navHandler, param) {
          Curry._1(navHandler, routeConfiguration);
        }));
  var implementRefurbishedProducts = FeatureFlags__LocalFeatureFlags.use("implementRefurbishedProducts");
  if (implementRefurbishedProducts === "off") {
    var tmp = {
      children: null,
      kind: "sharp",
      spacing: 0,
      style: Curry._1(styled, styles.container),
      testID: testID,
      testMode: match$3.usedForTesting
    };
    var tmp$1 = Belt_Option.map(onPress, (function (onPress) {
            return onPress;
          }));
    if (tmp$1 !== undefined) {
      tmp.onPress = Caml_option.valFromOption(tmp$1);
    }
    var tmp$2;
    if (mainImage !== undefined) {
      var size = mainImage.size;
      var tmp$3 = {
        image: {
          src: mainImage.url,
          width: size.width,
          height: size.height
        },
        size: isWideScreen ? ({
              NAME: "fixedHeightAutoWidth",
              VAL: {
                height: imageHeight,
                sizes: imageSizes
              }
            }) : ({
              NAME: "fixedHeight",
              VAL: {
                height: imageHeight,
                aspectRatio: imageAspectRatio
              }
            }),
        resizeMode: "contain",
        alt: Belt_Option.getWithDefault(mainImage.alt, name),
        style: Curry._1(styled, styles.image),
        testID: "product-scrollable-list-card-image"
      };
      if (imagePriority !== undefined) {
        tmp$3.priority = Caml_option.valFromOption(imagePriority);
      }
      tmp$2 = React.createElement(Ui__Card.$$Image.make, tmp$3);
    } else {
      var tmp$4 = {
        height: imageHeight
      };
      var tmp$5 = Bool$DecidrIoUtils.thenSome(!isWideScreen, imageHeight * imageAspectRatio);
      if (tmp$5 !== undefined) {
        tmp$4.width = tmp$5;
      }
      tmp$2 = React.createElement(Ui__ImageFallback.make, tmp$4);
    }
    var tmp$6;
    if (brand !== undefined) {
      var tmp$7 = {
        text: brand.name,
        size: "subtitle",
        fontWeight: "_500",
        numberOfLines: 1,
        testID: "product-card-brand-name"
      };
      if (textStyle !== undefined) {
        tmp$7.style = Caml_option.valFromOption(textStyle);
      }
      tmp$6 = React.createElement(Ui__Card.Description.make, tmp$7);
    } else {
      App__Report.unexpected("Missing brand for " + name + "", "App__Product__ScrollableListCard");
      tmp$6 = React.createElement(Ui__BrandNameFallback.make, {
            size: "subtitle",
            fontWeight: "_500",
            fontFamily: theme.fontFamily.dm,
            numberOfLines: 1
          });
    }
    var tmp$8 = {
      text: name,
      size: "subtitle",
      numberOfLines: 2,
      testID: "product-card-product-name"
    };
    if (textStyle !== undefined) {
      tmp$8.style = Caml_option.valFromOption(textStyle);
    }
    return React.createElement(Ui__Card.make, tmp, React.createElement(Ui__Card.Section.make, {
                    children: null,
                    spacing: match$1.cardImageSectionSpacing
                  }, SimpleReact$DecidrIoUtils.whenTrue(Ui__ProductPrelovedOnlyTag.determineIfVisible(match$3.sellingNewItems, match$3.sellingPrelovedItems), React.createElement(Ui__ProductTag.make, {
                            status: "prelovedOnly",
                            style: Ui__ProductPrelovedOnlyTag.commonStyling
                          })), React.createElement(Ui__AppLink.make, {
                        configuration: routeConfiguration,
                        children: tmp$2,
                        a11yLabel: "See more information about " + name + ""
                      })), React.createElement(App__Product__ScrollableListCard$GrowingSpacerWithLimitedHeight, {
                    minSize: theme.spacing._6,
                    maxSize: match$2.cardSpacing
                  }), React.createElement(Ui__Card.Section.make, {
                    children: null,
                    spacing: match$2.cardTextSectionSpacing
                  }, React.createElement(App__Product__SaleTag.make, {
                        product: fragmentRefs
                      }), React.createElement(Ui__AppLink.make, {
                        configuration: routeConfiguration,
                        children: tmp$6,
                        a11yLabel: a11yLabel,
                        wrapContent: true
                      }), React.createElement(Ui__AppLink.make, {
                        configuration: routeConfiguration,
                        children: React.createElement(Ui__Card.Description.make, tmp$8),
                        a11yLabel: a11yLabel,
                        wrapContent: true
                      }), React.createElement(Ui__AppLink.make, {
                        configuration: routeConfiguration,
                        children: React.createElement(App__Product__Price.make, {
                              product: fragmentRefs
                            }),
                        a11yLabel: a11yLabel,
                        wrapContent: true
                      })));
  }
  var tmp$9 = {
    product: fragmentRefs,
    testID: testID
  };
  if (onPress !== undefined) {
    tmp$9.onPress = Caml_option.valFromOption(onPress);
  }
  return React.createElement(Ui__ProductCard__Base.LinkWrapper.make, {
              configuration: routeConfiguration,
              a11yLabel: a11yLabel,
              children: React.createElement(App__Product__Card.make, tmp$9)
            });
}

var make = App__Product__ScrollableListCard;

export {
  GrowingSpacerWithLimitedHeight ,
  make ,
}
/* match Not a pure module */
