import { Status } from "@markot/feature-flags/src/local/FeatureFlags__AppSpecificConfig.gen";
import { getConfigFromNetwork } from "@markot/app/src/config/App__Config.gen";

export type FeatureFlagsRemoteConfig = Record<string, string | undefined>;

export const toFeatureFlagStatus = (
  value: string | undefined
): Status | undefined => {
  switch (value) {
    case "on":
      return "on";
    case "off":
      return "off";
    default:
      return undefined;
  }
};

export const toExtendedFFStatus = (
  ff: string | undefined
): "on" | "off" | "undetermined" => {
  switch (ff) {
    case "on":
      return "on";
    case "off":
      return "off";
    case undefined:
      return "undetermined";
    default:
      return "undetermined";
  }
};

export const getFeatureFlagsFromRemoteConfig =
  async (): Promise<FeatureFlagsRemoteConfig> => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const remoteConfig: any = await getConfigFromNetwork({
        onError: () => {
          // We will ignore the error and treat the app as we received
          // the empty JSON from the server.
          return JSON.stringify("{}");
        },
      });
      const featureFlags: FeatureFlagsRemoteConfig | undefined =
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        remoteConfig?.featureFlags;

      return featureFlags ?? {};
    } catch {
      console.log("Error loading remote config");
    }

    return {};
  };
