// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as Ui__GridList from "../../gridList/components/Ui__GridList.bs.js";
import * as ReactNative from "react-native";
import * as Ui__Responsive from "../../responsive/Ui__Responsive.bs.js";
import * as Group$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/layout/group/components/Group.bs.js";

var match = Ui__Styling.describe({
      scrollView: {
        TAG: /* Static */0,
        _0: {
          flexGrow: 1
        }
      },
      scrollContent: {
        TAG: /* Dynamic */1,
        _0: (function (props) {
            return {
                    paddingBottom: props.theme.sectionSpacing._1,
                    paddingHorizontal: props.theme.sectionSpacing._1,
                    paddingTop: props.paddingTop
                  };
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__ScrollableList(Props) {
  var data = Props.data;
  var renderItem = Props.renderItem;
  var style = Props.style;
  var contentContainerStyle = Props.contentContainerStyle;
  var testID = Props.testID;
  var wideBreakpointOpt = Props.wideBreakpoint;
  var numberOfColumnsOverride = Props.numberOfColumnsOverride;
  var gridChildWrapperStyle = Props.gridChildWrapperStyle;
  var wideBreakpoint = wideBreakpointOpt !== undefined ? wideBreakpointOpt : "l";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var match$1 = Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
        undefined,
        undefined,
        {
          isWideScreen: false,
          paddingTop: theme.spacing._12,
          numberOfColumns: 4
        },
        {
          isWideScreen: wideBreakpoint === "m",
          paddingTop: theme.spacing._14,
          numberOfColumns: 4
        },
        {
          isWideScreen: wideBreakpoint === "m" || wideBreakpoint === "l",
          paddingTop: theme.spacing._14,
          numberOfColumns: 5
        },
        {
          isWideScreen: wideBreakpoint === "m" || wideBreakpoint === "l" || wideBreakpoint === "xl",
          paddingTop: theme.spacing._16,
          numberOfColumns: 4
        },
        {
          isWideScreen: wideBreakpoint === "m" || wideBreakpoint === "l" || wideBreakpoint === "xl",
          paddingTop: theme.spacing._16,
          numberOfColumns: 5
        },
        {
          isWideScreen: false,
          paddingTop: theme.spacing._12,
          numberOfColumns: 2
        },
        undefined
      ]);
  var styled = Curry._1(stylesheet, {
        theme: theme,
        paddingTop: match$1.paddingTop
      });
  var renderedItems = Belt_Array.mapWithIndex(data, (function (index, item) {
          return Curry._1(renderItem, {
                      item: item,
                      index: index
                    });
        }));
  if (match$1.isWideScreen && ReactNative.Platform.OS === "web") {
    var tmp = {
      data: data,
      renderItem: renderItem,
      numberOfColumnsOverride: Belt_Option.getWithDefault(numberOfColumnsOverride, match$1.numberOfColumns)
    };
    if (contentContainerStyle !== undefined) {
      tmp.style = Caml_option.valFromOption(contentContainerStyle);
    }
    if (gridChildWrapperStyle !== undefined) {
      tmp.childWrapperStyle = Caml_option.valFromOption(gridChildWrapperStyle);
    }
    return React.createElement(Ui__GridList.make, tmp);
  }
  var tmp$1 = {
    contentContainerStyle: [
      Caml_option.some(Curry._1(styled, styles.scrollView)),
      contentContainerStyle
    ],
    horizontal: true,
    showsHorizontalScrollIndicator: false,
    children: React.createElement(Group$DecidrIoUniversalComponents.make, {
          spacing: theme.spacing._10,
          children: renderedItems,
          containerStyle: Curry._1(styled, styles.scrollContent),
          orientation: "horizontal"
        })
  };
  if (style !== undefined) {
    tmp$1.style = Caml_option.valFromOption(style);
  }
  if (testID !== undefined) {
    tmp$1.testID = testID;
  }
  return React.createElement(ReactNative.ScrollView, tmp$1);
}

var make = Ui__ScrollableList;

export {
  make ,
}
/* match Not a pure module */
