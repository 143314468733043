// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as ReactNative from "react-native";
import * as Ui__MarkotIcon from "../../icons/components/Ui__MarkotIcon.bs.js";

var match = Ui__Styling.describe({
      container: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center",
          justifyContent: "center"
        },
        _1: (function (props) {
            return {
                    backgroundColor: props.theme.colors.primaryShade4,
                    borderColor: props.theme.colors.info,
                    borderRadius: props.theme.roundness.circle,
                    borderWidth: props.theme.lineThickness.xxs,
                    opacity: props.disabled ? props.disabledOpacity : 1,
                    height: props.size,
                    width: props.size
                  };
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

function useSizes(size) {
  if (size === "m") {
    return [
            48,
            20
          ];
  } else if (size === "s") {
    return [
            38,
            16
          ];
  } else if (size === "xs") {
    return [
            28,
            12
          ];
  } else {
    return [
            60,
            24
          ];
  }
}

function Ui__CarouselButton(Props) {
  var onPress = Props.onPress;
  var directionOpt = Props.direction;
  var sizeOpt = Props.size;
  var disabledOpt = Props.disabled;
  var testOnly_pressedOpt = Props.testOnly_pressed;
  var testIDOpt = Props.testID;
  var containerStyle = Props.containerStyle;
  var underlayColor = Props.underlayColor;
  var disabledOpacityOpt = Props.disabledOpacity;
  var direction = directionOpt !== undefined ? directionOpt : "left";
  var size = sizeOpt !== undefined ? sizeOpt : "s";
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var testOnly_pressed = testOnly_pressedOpt !== undefined ? testOnly_pressedOpt : false;
  var testID = testIDOpt !== undefined ? testIDOpt : "carousel-button";
  var disabledOpacity = disabledOpacityOpt !== undefined ? disabledOpacityOpt : 0.25;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var match$1 = React.useState(function () {
        return false;
      });
  var setHovered = match$1[1];
  var match$2 = useSizes(size);
  var styled = Curry._1(stylesheet, {
        theme: theme,
        size: match$2[0],
        disabled: disabled,
        disabledOpacity: disabledOpacity
      });
  var tmp = {};
  var tmp$1 = match$1[0] && !disabled ? theme.lineThickness.s : undefined;
  if (tmp$1 !== undefined) {
    tmp.borderWidth = tmp$1;
  }
  return React.createElement(ReactNative.TouchableHighlight, {
              style: [
                Caml_option.some(Curry._1(styled, styles.container)),
                Caml_option.some(tmp),
                containerStyle
              ],
              underlayColor: Belt_Option.getWithDefault(underlayColor, theme.colors.backgroundVariant2),
              accessibilityRole: "button",
              disabled: disabled,
              onPress: (function (param) {
                  Curry._1(onPress, undefined);
                }),
              testID: testID,
              testOnly_pressed: testOnly_pressed,
              children: React.createElement(Ui__MarkotIcon.make, {
                    name: direction === "left" ? "chevron-left" : "chevron-right",
                    size: match$2[1],
                    color: theme.colors.primaryShade1
                  }),
              onMouseEnter: (function (param) {
                  Curry._1(setHovered, (function (param) {
                          return true;
                        }));
                }),
              onMouseLeave: (function (param) {
                  Curry._1(setHovered, (function (param) {
                          return false;
                        }));
                })
            });
}

var make = Ui__CarouselButton;

export {
  useSizes ,
  make ,
}
/* match Not a pure module */
