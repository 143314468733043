// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Ui__SaleTag from "../../../../../ui/src/saleTag/Ui__SaleTag.bs.js";
import * as ReactRelay from "react-relay";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as AppProductSaleTag_product_graphql from "../../../../../relay-codegen/generated/AppProductSaleTag_product_graphql.bs.js";

function use(fRef) {
  var data = ReactRelay.useFragment(AppProductSaleTag_product_graphql.node, fRef);
  return RescriptRelay_Internal.internal_useConvertedValue(AppProductSaleTag_product_graphql.Internal.convertFragment, data);
}

function App__Product__SaleTag(Props) {
  var product = Props.product;
  var match = use(product);
  if (match.compareAtPriceRange !== undefined) {
    return React.createElement(Ui__SaleTag.make, {});
  } else {
    return null;
  }
}

var make = App__Product__SaleTag;

export {
  make ,
}
/* react Not a pure module */
