// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactNativeSvg from "react-native-svg";

function Ui__BigRightArrowSvg(Props) {
  var sizeOpt = Props.size;
  var size = sizeOpt !== undefined ? sizeOpt : 200;
  return React.createElement(ReactNativeSvg.Svg, {
              viewBox: "0 0 " + String(586) + " " + String(464) + "",
              width: size,
              height: 464 / 586 * size,
              children: React.createElement(ReactNativeSvg.Path, {
                    d: "M0 464V0H371.055L586 231L371.055 464H0Z",
                    fill: "#282828"
                  }),
              fill: "none"
            });
}

var make = Ui__BigRightArrowSvg;

export {
  make ,
}
/* react Not a pure module */
