import { useLayoutEffect } from "react";

import { useWebDrawers } from "@markot/ui/src/webDrawers";

export const useCloseWebDrawersIfInitiallyOpen = () => {
  const { visibleDrawer, updateVisibleDrawer } = useWebDrawers();

  useLayoutEffect(() => {
    // When navigating to a page from the drawer, drawer will remain
    // open. In that case we want to close it.
    if (visibleDrawer !== undefined) {
      updateVisibleDrawer(undefined);
    }
    // Disabling this rule because this has to be called exactly once
    // on initial render, not whenever `visibleDrawer` and
    // `updateVisibleDrawer` changes
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
};
