// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../../group/components/Ui__Group.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as Ui__MarkotText from "../../text/components/Ui__MarkotText.bs.js";
import * as Ui__Responsive from "../../responsive/Ui__Responsive.bs.js";
import * as Ui__EmptyStateIllustration from "../../svg/emptyState/components/Ui__EmptyStateIllustration.bs.js";

var match = Ui__Styling.describe({
      container: {
        TAG: /* Dual */2,
        _0: {
          backgroundColor: "transparent",
          alignItems: "center"
        },
        _1: (function (theme) {
            return {
                    paddingVertical: theme.spacing._14
                  };
          })
      },
      description: {
        TAG: /* Static */0,
        _0: {
          textAlign: "center",
          maxWidth: 260
        }
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__ListEmptyState(Props) {
  var descriptionOpt = Props.description;
  var style = Props.style;
  var testIDOpt = Props.testID;
  var description = descriptionOpt !== undefined ? descriptionOpt : "No items to show! Try refreshing the page.";
  var testID = testIDOpt !== undefined ? testIDOpt : "list-empty-state";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet, theme);
  var match$1 = Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
        undefined,
        undefined,
        undefined,
        [
          theme.spacing._12,
          115
        ],
        undefined,
        [
          theme.spacing._14,
          120
        ],
        undefined,
        [
          theme.spacing._10,
          90
        ],
        undefined
      ]);
  return React.createElement(Ui__Group.make, {
              spacing: match$1[0],
              children: null,
              containerStyle: [
                Caml_option.some(Curry._1(styled, styles.container)),
                style
              ],
              testID: testID
            }, React.createElement(Ui__EmptyStateIllustration.make, {
                  size: match$1[1]
                }), React.createElement(Ui__MarkotText.make, {
                  size: "b1",
                  children: description,
                  style: Curry._1(styled, styles.description),
                  ellipsizeMode: "tail",
                  numberOfLines: 2
                }));
}

var make = Ui__ListEmptyState;

export {
  make ,
}
/* match Not a pure module */
