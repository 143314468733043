// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";
import * as App__Report from "../utils/App__Report.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../../ui/src/styling/Ui__Styling.bs.js";
import * as ReactRelay from "react-relay";
import * as Ui__GridList from "../../../ui/src/gridList/components/Ui__GridList.bs.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as Ui__ErrorState from "../../../ui/src/errorState/components/Ui__ErrorState.bs.js";
import * as Ui__Responsive from "../../../ui/src/responsive/Ui__Responsive.bs.js";
import * as Ui__PageSection from "../../../ui/src/pageSection/components/Ui__PageSection.bs.js";
import * as Bool$DecidrIoUtils from "@decidr-io/utils/src/bool/Bool.bs.js";
import * as Ui__ListEmptyState from "../../../ui/src/listEmptyState/components/Ui__ListEmptyState.bs.js";
import * as Ui__ScrollableList from "../../../ui/src/scrollableList/components/Ui__ScrollableList.bs.js";
import * as Ui__ListsLoadingView from "../../../ui/src/loading/components/Ui__ListsLoadingView.bs.js";
import * as App__Product__GridCard from "../product/components/gridCard/App__Product__GridCard.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as Ui__MarkotErrorBoundary from "../../../ui/src/error/markotErrorBoundary/Ui__MarkotErrorBoundary.bs.js";
import * as FeatureFlags__LocalFeatureFlags from "../../../feature-flags/src/local/FeatureFlags__LocalFeatureFlags.bs.js";
import * as App__Product__ScrollableListCard from "../product/components/scrollableListCard/App__Product__ScrollableListCard.bs.js";
import * as AppProductCollectionPaginationQuery_graphql from "../../../relay-codegen/generated/AppProductCollectionPaginationQuery_graphql.bs.js";
import * as AppProductCollection_productCollection_graphql from "../../../relay-codegen/generated/AppProductCollection_productCollection_graphql.bs.js";

var widthLimiterStyle = Ui__Responsive.widthLimiterStyle(undefined, undefined, undefined);

var getConnectionNodes = AppProductCollection_productCollection_graphql.Utils.getConnectionNodes;

function internal_makeRefetchableFnOpts(fetchPolicy, onComplete, param) {
  var tmp = {};
  var tmp$1 = RescriptRelay.mapFetchPolicy(fetchPolicy);
  if (tmp$1 !== undefined) {
    tmp.fetchPolicy = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = RescriptRelay_Internal.internal_nullableToOptionalExnHandler(onComplete);
  if (tmp$2 !== undefined) {
    tmp.onComplete = Caml_option.valFromOption(tmp$2);
  }
  return tmp;
}

function usePagination(fr) {
  var p = ReactRelay.usePaginationFragment(AppProductCollection_productCollection_graphql.node, fr);
  var data = RescriptRelay_Internal.internal_useConvertedValue(AppProductCollection_productCollection_graphql.Internal.convertFragment, p.data);
  return {
          data: data,
          loadNext: React.useMemo((function () {
                  return function (param, param$1, param$2) {
                    return p.loadNext(param, {
                                onComplete: RescriptRelay_Internal.internal_nullableToOptionalExnHandler(param$1)
                              });
                  };
                }), [p.loadNext]),
          loadPrevious: React.useMemo((function () {
                  return function (param, param$1, param$2) {
                    return p.loadPrevious(param, {
                                onComplete: RescriptRelay_Internal.internal_nullableToOptionalExnHandler(param$1)
                              });
                  };
                }), [p.loadPrevious]),
          hasNext: p.hasNext,
          hasPrevious: p.hasPrevious,
          isLoadingNext: p.isLoadingNext,
          isLoadingPrevious: p.isLoadingPrevious,
          refetch: React.useMemo((function () {
                  return function (param, param$1, param$2, param$3) {
                    return p.refetch(RescriptRelay_Internal.internal_cleanObjectFromUndefinedRaw(AppProductCollectionPaginationQuery_graphql.Internal.convertVariables(param)), internal_makeRefetchableFnOpts(param$1, param$2, undefined));
                  };
                }), [p.refetch])
        };
}

var match = Ui__Styling.describe({
      childWrapper: {
        TAG: /* Static */0,
        _0: {
          alignItems: "center"
        }
      }
    });

var stylesheet = match[1];

var styles = match[0];

function App__ProductCollection$List(Props) {
  var productCollection = Props.productCollection;
  var title = Props.title;
  var testIDOpt = Props.testID;
  var layout = Props.layout;
  var testID = testIDOpt !== undefined ? testIDOpt : "product-collection";
  var match = usePagination(productCollection);
  var match$1 = Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
        undefined,
        undefined,
        [
          "small",
          3
        ],
        undefined,
        [
          "medium",
          4
        ],
        [
          "large",
          4
        ],
        [
          "extraLarge",
          5
        ],
        [
          "small",
          4
        ],
        undefined
      ]);
  var screenSize = match$1[0];
  var match$2 = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match$2.theme;
  var styled = Curry._1(stylesheet, theme);
  var maybeNodes = match.data.products;
  var products = Belt_Option.map(maybeNodes, getConnectionNodes);
  if (products !== undefined) {
    if (products.length === 0) {
      return React.createElement(Ui__ListEmptyState.make, {});
    }
    Belt_Array.shuffleInPlace(products);
    var data;
    var exit = 0;
    switch (layout) {
      case "grid" :
          data = Belt_Array.slice(products, 0, match$1[1]);
          break;
      case "horizontal-list" :
          exit = 1;
          break;
      default:
        exit = 1;
    }
    if (exit === 1) {
      data = screenSize === "extraLarge" ? Belt_Array.slice(products, 0, 5) : (
          screenSize === "small" || screenSize === "medium" ? products : Belt_Array.slice(products, 0, 4)
        );
    }
    var numberOfItems = data.length;
    var imageSizes;
    var exit$1 = 0;
    switch (layout) {
      case "grid" :
          imageSizes = "((min-width: 320px) and (max-width: 576px)) 40vw, ((min-width: 576px) and (max-width: 1920px)) " + String(Caml_int32.div(90, numberOfItems > 0 ? numberOfItems : 1)) + "vw, (min-width: 1920px) 392px";
          break;
      case "horizontal-list" :
          exit$1 = 1;
          break;
      default:
        exit$1 = 1;
    }
    if (exit$1 === 1) {
      imageSizes = "((min-width: 1280px) and (max-width: 1920px)) " + String(Caml_int32.div(90, numberOfItems > 0 ? numberOfItems : 1)) + "vw, (min-width: 1920px) 392px";
    }
    var implementRefurbishedProducts = FeatureFlags__LocalFeatureFlags.use("implementRefurbishedProducts");
    switch (layout) {
      case "grid" :
          var tmp = {
            data: data,
            renderItem: (function (param) {
                return React.createElement(App__Product__GridCard.make, {
                            product: param.item.fragmentRefs,
                            imageSizes: imageSizes,
                            testID: testID + "-product"
                          });
              }),
            style: widthLimiterStyle
          };
          var tmp$1 = Bool$DecidrIoUtils.thenSome(implementRefurbishedProducts === "on", Curry._1(styled, styles.childWrapper));
          if (tmp$1 !== undefined) {
            tmp.childWrapperStyle = Caml_option.valFromOption(tmp$1);
          }
          return React.createElement(Ui__GridList.make, tmp);
      case "horizontal-list" :
          break;
      default:
        
    }
    var tmp$2 = {
      data: data,
      renderItem: (function (param) {
          var node = param.item;
          return React.createElement(App__Product__ScrollableListCard.make, {
                      product: node.fragmentRefs,
                      imageSizes: imageSizes,
                      testID: testID + "-product",
                      key: node.id
                    });
        }),
      wideBreakpoint: "xl"
    };
    var tmp$3 = Bool$DecidrIoUtils.thenSome(screenSize !== "small" && screenSize !== "medium", widthLimiterStyle);
    if (tmp$3 !== undefined) {
      tmp$2.contentContainerStyle = Caml_option.valFromOption(tmp$3);
    }
    var tmp$4 = Bool$DecidrIoUtils.thenSome(implementRefurbishedProducts === "on", Curry._1(styled, styles.childWrapper));
    if (tmp$4 !== undefined) {
      tmp$2.gridChildWrapperStyle = Caml_option.valFromOption(tmp$4);
    }
    return React.createElement(Ui__ScrollableList.make, tmp$2);
  }
  App__Report.unexpected("Didn't receive data for " + title + " product collection", "App__ProductCollection");
  return React.createElement(Ui__ErrorState.make, {
              children: null
            }, React.createElement(Ui__ErrorState.Title.make, {
                  text: "An error occurred",
                  color: theme.colors.error
                }), React.createElement(Ui__ErrorState.Description.make, {
                  text: "Could not show the list of " + title + " products."
                }));
}

function App__ProductCollection(Props) {
  var productCollection = Props.productCollection;
  var titleOpt = Props.title;
  var testIDOpt = Props.testID;
  var layout = Props.layout;
  var title = titleOpt !== undefined ? titleOpt : "Most liked";
  var testID = testIDOpt !== undefined ? testIDOpt : "product-collection";
  return React.createElement(Ui__PageSection.make, {
              children: null,
              testID: testID + "-list-container",
              accent: true
            }, React.createElement(Ui__PageSection.CommonHeading.make, {
                  title: title,
                  containerStyle: widthLimiterStyle,
                  route: {
                    TAG: /* Search */18,
                    searchTerm: undefined,
                    loadDataInitially: true,
                    sellingNewItems: undefined,
                    brand: undefined,
                    category: undefined,
                    productType: undefined
                  },
                  a11yLabel: "Go browse all products"
                }), React.createElement(Ui__MarkotErrorBoundary.make, {
                  debugContext: "ProductCollection List error boundary",
                  children: React.createElement(React.Suspense, {
                        children: React.createElement(App__ProductCollection$List, {
                              productCollection: productCollection,
                              title: title,
                              testID: testID,
                              layout: layout
                            }),
                        fallback: React.createElement(Ui__ListsLoadingView.make, {
                              testID: testID + "-loading-indicator"
                            })
                      })
                }));
}

var make = App__ProductCollection;

export {
  make ,
}
/* widthLimiterStyle Not a pure module */
