import { useEffect, useState } from "react";
import { useRouter, NextRouter } from "next/router";

import { headerLeftType as HeaderLeftType } from "@markot/ui/src/mainHeaderBar/components/Ui__MainHeaderBar.gen";
import {
  authStatus as AuthStatus,
  useAuth,
} from "@markot/app/src/auth/context/AuthContext.gen";

export const useHeaderLeftKind = (): HeaderLeftType => {
  const router = useRouter();
  const [historyLength, setHistoryLength] = useState(1);
  useEffect(() => {
    if (typeof window !== "undefined") {
      setHistoryLength(window.history.length);
    }
  }, []);
  if (historyLength > 1) {
    return { NAME: "backButton", VAL: () => router.back() };
  } else {
    return "homePage";
  }
};

export const useRedirectWhenNotAuthenticated = (): {
  status: AuthStatus | undefined;
  router: NextRouter;
} => {
  const router = useRouter();
  const status = useAuth().authInfo?.status;
  useEffect(() => {
    if (status === undefined || status === "notAuthenticated") {
      router.replace("/login");
    }
  }, [router, status]);

  return { status, router };
};

export const useRedirectToHomeWhenAuthenticated = (): NextRouter => {
  const router = useRouter();
  const status = useAuth().authInfo?.status;
  useEffect(() => {
    if (status === "authenticated") {
      router.replace("/");
    }
  }, [router, status]);

  return router;
};
