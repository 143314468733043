// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as Ui__Responsive from "../../responsive/Ui__Responsive.bs.js";
import * as Tiles$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/layout/tiles/components/Tiles.bs.js";

var match = Ui__Styling.describe({
      container: {
        TAG: /* Dynamic */1,
        _0: (function (props) {
            return {
                    paddingBottom: props.theme.sectionSpacing._1,
                    paddingHorizontal: props.theme.sectionSpacing._1,
                    paddingTop: props.paddingTop
                  };
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

var noSpacingStyle = {
  paddingBottom: 0,
  paddingHorizontal: 0,
  paddingTop: 0
};

function Ui__GridList(Props) {
  var data = Props.data;
  var renderItem = Props.renderItem;
  var style = Props.style;
  var testID = Props.testID;
  var numberOfColumnsOverride = Props.numberOfColumnsOverride;
  var itemSpacingOverride = Props.itemSpacingOverride;
  var childWrapperStyle = Props.childWrapperStyle;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var media = Ui__Responsive.useMedia(undefined)(undefined);
  var match$1 = Curry.app(media.mediaSelect, [
        undefined,
        {
          numOfColumns: 2,
          itemSpacing: theme.spacing._6,
          paddingTop: theme.spacing._12
        },
        {
          numOfColumns: 3,
          itemSpacing: theme.spacing._4,
          paddingTop: theme.spacing._12
        },
        {
          numOfColumns: 3,
          itemSpacing: theme.spacing._6,
          paddingTop: theme.spacing._14
        },
        {
          numOfColumns: 4,
          itemSpacing: theme.spacing._10,
          paddingTop: theme.spacing._14
        },
        {
          numOfColumns: 4,
          itemSpacing: theme.spacing._12,
          paddingTop: theme.spacing._16
        },
        {
          numOfColumns: 5,
          itemSpacing: theme.spacing._16,
          paddingTop: theme.spacing._16
        },
        {
          numOfColumns: 2,
          itemSpacing: theme.spacing._6,
          paddingTop: theme.spacing._12
        },
        undefined
      ]);
  var styled = Curry._1(stylesheet, {
        theme: theme,
        paddingTop: match$1.paddingTop
      });
  var renderedItems = Belt_Array.mapWithIndex(data, (function (index, item) {
          return Curry._1(renderItem, {
                      item: item,
                      index: index
                    });
        }));
  var tmp = {
    children: renderedItems,
    columns: Belt_Option.getWithDefault(numberOfColumnsOverride, match$1.numOfColumns),
    spacing: Belt_Option.getWithDefault(itemSpacingOverride, match$1.itemSpacing),
    containerStyle: [
      Caml_option.some(Curry._1(styled, styles.container)),
      style
    ],
    testID: Belt_Option.getWithDefault(testID, "grid-list-tiles-content")
  };
  if (childWrapperStyle !== undefined) {
    tmp.childWrapperStyle = Caml_option.valFromOption(childWrapperStyle);
  }
  return React.createElement(Tiles$DecidrIoUniversalComponents.make, tmp);
}

var make = Ui__GridList;

export {
  noSpacingStyle ,
  make ,
}
/* match Not a pure module */
