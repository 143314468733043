// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../../../../../ui/src/group/components/Ui__Group.bs.js";
import * as App__Money from "../../../money/components/App__Money.bs.js";
import * as Ui__Styling from "../../../../../ui/src/styling/Ui__Styling.bs.js";
import * as ReactRelay from "react-relay";
import * as Ui__MarkotText from "../../../../../ui/src/text/components/Ui__MarkotText.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as SimpleReact$DecidrIoUtils from "@decidr-io/utils/src/react/SimpleReact.bs.js";
import * as AppProductPrice_product_graphql from "../../../../../relay-codegen/generated/AppProductPrice_product_graphql.bs.js";

function use(fRef) {
  var data = ReactRelay.useFragment(AppProductPrice_product_graphql.node, fRef);
  return RescriptRelay_Internal.internal_useConvertedValue(AppProductPrice_product_graphql.Internal.convertFragment, data);
}

var match = Ui__Styling.describe({
      container: {
        TAG: /* Static */0,
        _0: {
          alignItems: "center"
        }
      },
      price: {
        TAG: /* Static */0,
        _0: {
          fontWeight: "bold"
        }
      },
      discountedPrice: {
        TAG: /* Static */0,
        _0: {
          fontWeight: "500",
          textDecorationLine: "line-through"
        }
      }
    });

var stylesheet = match[1];

var styles = match[0];

function App__Product__Price(Props) {
  var product = Props.product;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet, theme);
  var match$1 = use(product);
  var priceRange = match$1.priceRange;
  var compareAtPriceRange = match$1.compareAtPriceRange;
  var variantsHaveSamePrice = priceRange.min.amount === priceRange.max.amount;
  return React.createElement(Ui__Group.make, {
              spacing: theme.spacing._2,
              children: null,
              containerStyle: Curry._1(styled, styles.container),
              orientation: "horizontal"
            }, React.createElement(Ui__MarkotText.make, {
                  size: "price3",
                  children: null,
                  style: Curry._1(styled, styles.price)
                }, SimpleReact$DecidrIoUtils.whenTrue(!variantsHaveSamePrice, "From "), React.createElement(App__Money.make, {
                      money: priceRange.min.fragmentRefs
                    })), compareAtPriceRange !== undefined ? SimpleReact$DecidrIoUtils.whenTrue(variantsHaveSamePrice, React.createElement(Ui__MarkotText.make, {
                        size: "price3",
                        color: theme.colors.info,
                        children: React.createElement(App__Money.make, {
                              money: compareAtPriceRange.max.fragmentRefs
                            }),
                        style: Curry._1(styled, styles.discountedPrice)
                      })) : null);
}

var make = App__Product__Price;

export {
  make ,
}
/* match Not a pure module */
